document.addEventListener('DOMContentLoaded', () => {
  if (!document.querySelectorAll('.switcher-wrapper')?.length) return

  const hash = window.location.hash
  let activeSwitch = 1

  if (hash === '#flexi') activeSwitch = 2
  else if (hash === '#combo') activeSwitch = 3
  else if (hash === '#vykup') activeSwitch = 4
  document.querySelectorAll('.switcher-wrapper').forEach(wrapperItem => {
    wrapperItem.classList.add(`active-${activeSwitch}`)
  })

  const switcherItems = document.querySelectorAll('.switcher-wrapper .switcher li')
  switcherItems.forEach(item => {
    const classesToRemove = [...Array(switcherItems.length).keys()].map(
      switcherItemIndex => `active-${switcherItemIndex + 1}`
    )
    item.addEventListener('click', event => {
      const wrapper = event.target.closest('.switcher-wrapper')
      const switcher = event.target.closest('.switcher')
      const liEl = event.target.closest('li')
      const index = Array.prototype.indexOf.call(switcher.children, liEl)
      activeSwitch = index + 1

      wrapper.classList.remove(...classesToRemove)
      wrapper.classList.add(`active-${activeSwitch}`)
    })
  })
})

window.addEventListener(
  'hashchange',
  () => {
    const newHash = window.location.hash
    let activeSwitch = 1
    if (newHash === '#fix') activeSwitch = 1
    else if (newHash === '#flexi') activeSwitch = 2
    else if (newHash === '#combo') activeSwitch = 3
    else if (newHash === '#vykup') activeSwitch = 4
    document.querySelectorAll('.switcher-wrapper').forEach(wrapperItem => {
      const switcherItems = document.querySelectorAll('.switcher-wrapper .switcher li')
      const classesToRemove = [...Array(switcherItems.length).keys()].map(
        switcherItemIndex => `active-${switcherItemIndex + 1}`
      )
      wrapperItem.classList.remove(...classesToRemove)
      wrapperItem.classList.add(`active-${activeSwitch}`)
    })
  },
  false
)
