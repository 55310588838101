const serialize = data => {
  const values = {}
  for (const [key, value] of data) {
    if (values[key] !== undefined) {
      if (!Array.isArray(values[key])) {
        values[key] = [values[key]]
      }
      // eslint-disable-next-line fp/no-mutating-methods
      values[key].push(value)
    } else {
      values[key] = value
    }
  }
  return values
}

const formEventListener = () => {
  // Feedback forms
  document.querySelectorAll('.input-subject').forEach(item => {
    item.style.display = 'none'
  })

  const onSendResponse = form => response => {
    if (response.status >= 400) {
      form.querySelector('.error-message').style.display = 'block'
    } else {
      form.querySelectorAll('input, textarea').forEach(input => {
        input.value = ''
      })
      form.querySelector('.success-message').style.display = 'block'
    }
    form.querySelector('button').removeAttribute('disabled')
    return response
  }

  document.querySelectorAll('.feedback-forms form').forEach(form => {
    form.addEventListener('submit', event => {
      event.preventDefault()
      event.target.querySelector('.success-message').style.display = 'none'
      event.target.querySelector('.error-message').style.display = 'none'
      event.target.querySelector('button').setAttribute('disabled', 'disabled')
      try {
        const values = serialize(new window.FormData(event.target))
        let service
        if (values.service === 'electricity') service = 'Elektrina'
        else if (values.service === 'gas') service = 'Plyn'
        else if (values.service === 'advising') service = 'Poradenstvo'
        fetch(`/contact-message.php`, {
          method: 'POST',
          body: JSON.stringify({
            name: values.name,
            email: values.email,
            messageBody: values.messageBody,
            language: values.language,
            surname: values.surname,
            company: values.company,
            phone: values.phone,
            subject: values.subject,
            service
          }),
          headers: {
            'Content-Type': 'application/json',
            token: 'eesfgk4568sdjr8qasw11xck168zff0'
          }
        })
          .then(onSendResponse(event.target))
          .catch(error => {
            console.error(error)
            event.target.querySelector('.error-message').style.display = 'block'
            event.target.querySelector('button').removeAttribute('disabled')
          })
      } catch (e) {
        console.error(e)
        event.target.querySelector('.error-message').style.display = 'block'
        event.target.querySelector('button').removeAttribute('disabled')
      }
    })
  })
}

document.addEventListener('DOMContentLoaded', formEventListener)

const openContactModal = () => {
  if (window.location.hash === '#chcem-ponuku') {
    window.location.hash = ''
    document.querySelector('.contact-modal .js-modal-open')?.click?.()
    if (document.querySelector('.js-modal-content .feedback-forms')) {
      formEventListener()
      const isGasPage = !!document.querySelector('main.gas-page')
      const isAdvisingPage = !!document.querySelector('main.advising-page')
      if (isGasPage || isAdvisingPage) {
        document.querySelectorAll('.id-modal-content input[name="service"]').forEach(item => {
          item.removeAttribute('checked')
          if (isGasPage && item.value === 'gas') item.setAttribute('checked', 'checked')
          else if (isAdvisingPage && item.value === 'advising') item.setAttribute('checked', 'checked')
        })
      }
    }
  }
}

window.addEventListener('hashchange', openContactModal, false)
document.addEventListener('DOMContentLoaded', openContactModal)

document.addEventListener('DOMContentLoaded', () => {
  if (document.location.hash === '#form-start') {
    setTimeout(() => window.scrollBy({ top: -160 }), 0)
  }
})
