/* eslint-disable max-nested-callbacks */
document.addEventListener('DOMContentLoaded', () => {
  document.querySelectorAll('.accordion-panel').forEach(item => {
    item.addEventListener('click', event => {
      if (!item?.classList.contains('open')) {
        const parentAccordion = event.target.closest('.accordion-panels')
        parentAccordion.querySelectorAll('.accordion-panel').forEach(accItem => {
          accItem.classList.remove('open')
        })
        item.classList.add('open')
      }
    })
  })
})

const onAdvisingHash = () => {
  if (document.location.hash === '#poradenstvo') {
    document.querySelector('.accordion-panel.advising').click()
    setTimeout(() => window.scrollBy({ top: -100 }), 0)
  }
}

window.addEventListener('DOMContentLoaded', onAdvisingHash)
window.addEventListener('hashchange', onAdvisingHash, false)
