const hamburgerClickHandler = () => {
  document.documentElement.classList.add('is-mobile-menu')
}

const mobileMenuCloseHandler = () => {
  document.documentElement.classList.remove('is-mobile-menu')
}

document.addEventListener('DOMContentLoaded', () => {
  const hamburgerButton = document.querySelector('.hamburger-button')
  hamburgerButton?.addEventListener('click', hamburgerClickHandler)
  const mobileMenuCloseButton = document.querySelector('.mobile-menu-close-button')
  mobileMenuCloseButton?.addEventListener('click', mobileMenuCloseHandler)
  const mobileMenuElement = document.querySelector('#mobile-menu')
  mobileMenuElement?.addEventListener('click', event => {
    if (event.target === mobileMenuElement) mobileMenuCloseHandler()
  })
})
